<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="12">
        <base-material-card color="warning">
          <template v-slot:heading>
            <h2 class="h1 font-weight-light ">
              <v-icon large left>mdi-file-send</v-icon>การย้ายสายการสอน กรณีพิเศษ
            </h2>
          </template>
          <v-card class="pa-2 ma-6">
            <v-card-title>
              <div class="font-weight-light v-size--x-large">
                <v-icon large left>mdi-account-details</v-icon> ข้อมูลเบื้องต้น
                รหัสรายการอ้างอิง {{ id_ref }}
              </div>
            </v-card-title>
            <v-row>
              <v-col cols="12" sm="6">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>
                    ชื่อ-นามสกุล : {{ user.title_s }}{{ user.frist_name }}
                    {{ user.last_name }}
                  </h2>
                  <h3>รหัสบัตรประชาชน : {{ user.id_card }}</h3>
                  <h3>เลขที่ตำแหน่ง : {{ user.id_position }}</h3>
                  <h3>วิทยฐานะ : {{ user.rang_name }}</h3>
                  <h3>สังกัด (สถานศึกษา) : {{ user.college_name }}</h3>
                  <h3>
                    ประเภทการบรรจุ :
                    <v-chip
                      v-if="user.type_personnel === 'local_dev'"
                      color="info"
                    >
                      <h3>ครูพัฒนาท้องถิ่น</h3>
                    </v-chip>
                  </h3>
                  <hr />
                  <h3>
                    วันที่เริ่มบรรจุเข้ารับราชการ : {{ date_appoin_ch || "-" }}
                  </h3>
                  <h3>ระยะเวลาตั้งแต่บรรจุเข้ารับราชการ : {{ get_gov_Age }}</h3>
                  <h3>สถานะภาพ : {{ marital_status }}</h3>
                </v-alert>
              </v-col>

              <v-col cols="12" sm="6" align="center">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <h2>
                    ครั้งที่ : {{ periods.period_times }} ประจำปี :
                    {{ parseInt(periods.period_year) + 543 }}
                  </h2>
                  <h3>เขียนที่ : {{ user.college_name }}</h3>
                  <h3>วันที่ยื่นคำร้อง : {{ date_today }}</h3>
                  <hr />
                  <div v-if="user.date_app_now === ''">
                    <v-alert prominent type="error">
                      <v-row>
                        <v-col class="grow">
                          <h3>
                            ให้งานบุคลากร วิทยาลัย
                            ดำเนินการเกี่ยวกับวันที่ปฏิบัติงาน ณ
                            สถานศึกษาปัจจุบันให้เรียบร้อย
                          </h3>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </div>
                  <div v-else>
                    <h3>
                      วันที่เริ่มปฏิบัติหน้าที่ในสถานศึกษาปัจจุบัน :
                      {{
                        user.date_app_now
                          | moment("add", "543 years")
                          | moment("D MMMM YYYY")
                      }}
                    </h3>
                    <h2>ปฏิบัติหน้าที่เป็นเวลา : {{ get_Appoint_Age }}</h2>
                  </div>

                  <v-btn
                    v-if="transference_personnels.transfer_status === 'save'"
                    rounded
                    block
                    x-large
                    color="info"
                    dark
                    class="mb-2"
                    :href="'#/user/print_info_sp/' + id_ref"
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <span>พิมพ์แบบแสดงความประสงค์ขอย้าย กรณีพิเศษ</span>
                  </v-btn>                 
                </v-alert>
              </v-col>

              <v-col cols="12" sm="12">
                <v-alert
                  border="left"
                  colored-border
                  color="green darken-1"
                  elevation="2"
                  type="info"
                >
                  <v-card>
                    <v-card-title class="mt-8">
                      <v-avatar size="56">
                        <v-icon large left>mdi-cast-education</v-icon>
                      </v-avatar>
                      <p class="ml-3">ประวัติการศึกษา</p>
                    </v-card-title>

                    <v-card-text>
                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="item in personnel_educations"
                          :key="item.id_red"
                          small
                        >
                          <div>
                            <div class="font-weight-normal">
                              {{ item.education_level }} คณะวิชา :
                              {{ item.faculty_name }} สาขาวิชา :
                              {{ item.branch_name }} สำเร็จปีการศึกษา :
                              {{ item.year_finish }}
                            </div>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                  </v-card>
                </v-alert>
              </v-col>
            </v-row>
          </v-card>

          <v-form v-if="transference_personnels.transfer_status === 'save'">
            <v-alert border="right" colored-border type="error" elevation="2">
              <h1>
                ท่านได้ยืนแบบแสดงความประสงค์ขอย้ายกรณีปกติ
                ของข้าราชการครูและบุคลากรทางการศึกษา
                ผ่านระบบออนไลน์เป็นที่เรียนร้อย กรุณา
                พิมพ์แบบแสดงความประสงค์ขอย้าย
                เพื่อเสนอความเห็นชอบจากผู้บริหารสถานศึกษา / ผู้อำนวยการสำนัก /
                ผู้อำนวยการศูนย์ฯ
              </h1>
            </v-alert>
          </v-form>
          <!-- New Save -->

          <v-form
            ref="addtransference_personnelform"
            lazy-validation
            v-else-if="
              get_gov_Age_year > 0 &&
                user.position_name === 'ครู' &&
                transference_personnels.transfer_status !== 'temporary' &&
                transference_personnels.transfer_status !== 'save'
            "
          >
            <div>
           <!--    <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-electron-framework</v-icon>
                    รายละเอียดองค์ประกอบ / ตัวชี้วัดที่ 1.4
                  </div>
                </v-card-title>
                <v-row>
                  <v-col cols="12" md="12" class="pa-5">
                    การแสดงความรู้ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก
                    / หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
                    <br />
                    <span class="red--text">
                      พิมพ์แบบแสดงความประสงค์ขอย้าย และดำเนินการจัดทำ
                      เพื่อเสนอความเห็นชอบจากผู้บริหารสถานศึกษา และให้
                      สถานศึกษาดำเนินการจัดส่ง ส่วนกลาง</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" class="text-center">
                    <v-btn
                      color="info"
                      :href="'#/user/print_14_form/' + user.id_card"
                      target="_blank"
                      ><v-icon>mdi-printer</v-icon>
                      แบบฟอร์มเอกสารการแสดงความรู้ความสามารถ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card> -->

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-electron-framework</v-icon>
                    ปัจจุบันปฏิบัติการสอน
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="addtransference_personnel.teaching_status"
                        >
                          <v-radio value="no_tech">
                            <template v-slot:label>
                              <div>
                                <strong
                                  class="warning--text"
                                  v-on:click="clear_tech()"
                                  >ไม่ได้ทำการสอน</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="teacher">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ทำการสอน</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.subject_1"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 1:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.subject_2"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 2:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.subject_3"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 3:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.subject_4"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 4:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="
                              addtransference_personnel.perform_other_duties
                            "
                            outlined
                            label="หน้าที่อื่นที่ได้รับมอบหมาย :"
                            prepend-icon="mdi-account-network"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-office-building-marker</v-icon>
                    อยู่ระหว่างช่วยปฏิบัติราชการหรือไม่
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="addtransference_personnel.service_status"
                        >
                          <v-radio value="not_service">
                            <template v-slot:label>
                              <div>
                                <strong
                                  class="primary--text"
                                  v-on:click="clear_gov_service()"
                                  >ไม่อยู่</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="gov_service">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text"
                                  >ช่วยปฏิบัติราชการ</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            outlined
                            :items="colleges"
                            item-text="college_name"
                            item-value="college_ID"
                            v-model="addtransference_personnel.ser_college_code"
                            label="สถานศึกษา"
                            prepend-icon="mdi-office-building-marker"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.ser_order"
                            :counter="100"
                            outlined
                            label="เริ่มปฏิบัติราชการ คำสั่งที่ :"
                            prepend-icon="mdi-office-building-marker"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="addtransference_personnel.ser_date"
                                label="เมื่อวันที่ :"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="addtransference_personnel.ser_date"
                              @input="menu2 = false"
                              locale="th"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="addtransference_personnel.ser_reason_help"
                            :counter="100"
                            outlined
                            label="เหตุผลที่ช่วยปฏิบัติราชการ :"
                            prepend-icon="mdi-office-building-marker"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate</v-icon>
                    อยู่ระหว่างทดลองปฏิบัติราชการหรือการเตรียมความพร้อมและพัฒนาอย่างเข้มหรือไม่
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="addtransference_personnel.in_preparation"
                        >
                          <v-radio value="no_prepare">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="prepare">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate-outline</v-icon>
                    อยู่ระหว่างลาศึกษาต่อเต็มเวลาหรือไม่
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="addtransference_personnel.on_study_leave"
                        >
                          <v-radio value="no_leave">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="leave">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate-outline</v-icon>
                    ไม่ติดเงื่อนไขการบรรจุและแต่งตั้งตามหลักเกณฑ์และวิธีการสอบแข่งขันหรือคัดเลือก
                    หรือเงื่อนไขการแต่งตั้ง
                    ให้มีหรือเลื่อนวิทยฐานะตามหลักเกณฑ์และวิธีการที่ ก.ค.ศ.
                    กำหนด หรือเงื่อนไขอื่นใดที่ ก.ค.ศ. หรือหน่วยงานอื่นกำหนด
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="addtransference_personnel.not_conditions"
                        >
                          <v-radio value="not_con">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="con">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-card>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex col12 md6>
                            <v-text-field
                              :disabled="
                                addtransference_personnel.not_conditions ===
                                  'not_con'
                              "
                              v-model="
                                addtransference_personnel.not_conditionsOrder
                              "
                              :counter="100"
                              outlined
                              label="ตามคำสั่งที่ :"
                              prepend-icon="mdi-office-building-marker"
                            ></v-text-field>
                          </v-flex>

                          <v-flex col12 md6>
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="
                                    addtransference_personnel.not_conditions ===
                                      'not_con'
                                  "
                                  v-model="
                                    addtransference_personnel.not_conditionsDate
                                  "
                                  label="ลงวันที่ :"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  addtransference_personnel.not_conditionsDate
                                "
                                scrollable
                                locale="th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-flag-letiant</v-icon>
                    ขอย้ายไปปฏิบัติหน้าที่ในตำแหน่งและหน่วยงานแห่งใหม่
                    เรียงลำดับดังนี้
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>
                <v-col cols="12" sm="12">
                  <v-card class="pa-2 ma-2">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-card class="px-5 py-3" color="#FADBD8">
                          <v-btn
                            class="ma-2"
                            x-large
                            color="success"
                            dark
                            rounded
                            @click.stop="addtransference_locationdialogsubmit()"
                          >
                            <v-icon>mdi-selection-multiple-marker</v-icon>
                            <span> เลือกหน่วยงานแห่งใหม่</span>
                          </v-btn>
                          <v-data-table
                            color="success"
                            :loading="loading"
                            :headers="header_trans"
                            :items="transference_locations"
                          >
                            <template v-slot:item.action="{ item }">
                              <v-icon
                                color="red"
                                @click.stop="
                                  transference_locationDelete(item.id_tfl)
                                "
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <h3>
                          ขอย้ายสับเปลี่ยนกับ :
                          {{ personnel_temporarys.title_s }}
                          {{ personnel_temporarys.frist_name }}
                          {{ personnel_temporarys.last_name }}
                        </h3>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="addtransference_personnel.switch_position"
                          :counter="13"
                          outlined
                          label="บัตรประชาชน"
                          prepend-icon="mdi-account-details"
                          request
                          v-on:keyup.enter="OnEnter()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <v-text-field
                          :value="personnel_temporarys.position_name"
                          :counter="100"
                          outlined
                          label="ตำแหน่ง :"
                          prepend-icon="mdi-account-star"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          :value="personnel_temporarys.rang_name"
                          :counter="100"
                          outlined
                          label="วิทยฐานะ :"
                          prepend-icon="mdi-account-details"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :value="personnel_temporarys.id_position"
                          :counter="100"
                          outlined
                          label="เลขที่ตำแหน่ง :"
                          prepend-icon="mdi-account-details"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :value="personnel_temporarys.college_name"
                          :counter="100"
                          outlined
                          label="สังกัด :"
                          prepend-icon="mdi-flag-letiant"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <span
                          >*
                          กรณีย้ายสับเปลี่ยนให้กรอกหมายเลขบัตรประจำตัวประชาชนของผู้ที่ท่านประสงค์สับเปลี่ยน
                          และกดปุ่ม Enter เพื่อดำเนินการค้นหา
                          หากไม่ปรากฏแสดงว่าไม่มีบุคคลดังกล่าวในระบบ
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div
                    class="font-weight-light v-size--x-large"
                    color="pink darken-2"
                  >
                    <v-icon large left>mdi-account-details</v-icon>
                    เหตุผลการขอย้าย
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-col cols="12" sm="12">
                  <v-card class="pa-2">
                    <v-card-text>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            <v-icon></v-icon> ย้ายเพื่ออยู่ร่วมคู่สมรส
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="addtransference_personnel.reason_1"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-text-field
                          v-model="addtransference_personnel.reason_1_spouse"
                          :disabled="!addtransference_personnel.reason_1"
                          label="คู่สมรสชื่อ : "
                        ></v-text-field>
                        <v-text-field
                          v-model="
                            addtransference_personnel.reason_1_occupation
                          "
                          :disabled="!addtransference_personnel.reason_1"
                          label="คู่สมรสประกอบอาชีพ : "
                        ></v-text-field>
                        <v-text-field
                          v-model="addtransference_personnel.reason_1_location"
                          :disabled="!addtransference_personnel.reason_1"
                          label="สถานที่ประกอบอาชีพของคู่สมรส : "
                        ></v-text-field>
                        <v-autocomplete
                          v-model="addtransference_personnel.reason_1_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!addtransference_personnel.reason_1"
                          label="ภูมิลำเนาของคู่สมรส จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            ย้ายเพื่อดูแลบิดา มารดา :
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="addtransference_personnel.reason_2"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-autocomplete
                          :items="reason_2_fyears"
                          v-model="addtransference_personnel.reason_2_fyear"
                          :disabled="!addtransference_personnel.reason_2"
                          label="อายุของบิดาปี"
                        ></v-autocomplete>
                        <v-autocomplete
                          :items="reason_2_myears"
                          v-model="addtransference_personnel.reason_2_myear"
                          :disabled="!addtransference_personnel.reason_2"
                          label="อายุของมารดาปี"
                        ></v-autocomplete>
                        <v-autocomplete
                          v-model="addtransference_personnel.reason_2_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!addtransference_personnel.reason_2"
                          label="ภูมิลำเนาของบิดา มารดา จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            ย้ายกลับภูมิลำเนาของข้าพเจ้า จังหวัด :
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="addtransference_personnel.reason_3"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-autocomplete
                          v-model="addtransference_personnel.reason_3_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!addtransference_personnel.reason_3"
                          label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            เหตุผลอื่น :
                          </div>
                        </v-card-title>
                        <v-checkbox
                          v-model="addtransference_personnel.reason_4"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-textarea
                          v-model="addtransference_personnel.reason_4_detail"
                          :disabled="!addtransference_personnel.reason_4"
                          label="(ระบุ) : "
                        ></v-textarea>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-details</v-icon>
                    เอกสารประกอบการพิจารณา
                  </div>
                  <v-spacer></v-spacer>

                  <v-btn
                    @click="addtransference_personnelSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="yellow">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row class="pa-4 mt-0">
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_1"
                          label="สำเนา กพ.7/ก.ค.ศ.16 ที่เป็นปัจจุบัน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_2"
                          label="สำเนาทะเบียนบ้านของตน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_3"
                          label="สำเนาทะเบียนบ้านของบิดา มารดา"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_4"
                          label="สำเนาทะเบียนบ้านของคู่สมรส"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_5"
                          label="สำเนาทะเบียนสมรส"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="addtransference_personnel.evidence_info_6"
                          label="สำเนาคำสั่งย้ายไปดำรงตำแหน่งใหม่ของคู่สมรส"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-center">
                      <v-btn
                        rounded
                        x-large
                        color="secondary"
                        target="_blank"
                        @click="previewReportShow()"
                        ><v-icon class="pr-2">mdi-eye</v-icon>
                        แบบฟอร์มตัวอย่างคำร้องขอย้าย</v-btn
                      >

                      <v-btn
                        rounded
                        x-large
                        color="primary"
                        @click="addtransference_personnelSubmit()"
                        class="ma-2"
                        ><v-icon color="yellow">mdi-content-save</v-icon>
                        บันทึกเอกสาร</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-form>
          <!-- Temporary -->
          <v-form
            ref="updatetransference_personnelsform"
            lazy-validation
            v-else-if="
              get_gov_Age_year > 0 &&
                user.position_name === 'ครู' &&
                transference_personnels.transfer_status === 'temporary'
            "
          >
            <div>
          <!--     <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-electron-framework</v-icon>
                    รายละเอียดองค์ประกอบ / ตัวชี้วัดที่ 1.4
                  </div>
                </v-card-title>
                <v-row>
                  <v-col cols="12" md="12" class="pa-5">
                    การแสดงความรู้ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก
                    / หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
                    <br />
                    <span class="red--text">
                      พิมพ์แบบแสดงความประสงค์ขอย้าย และดำเนินการจัดทำ
                      เพื่อเสนอความเห็นชอบจากผู้บริหารสถานศึกษา และให้
                      สถานศึกษาดำเนินการจัดส่ง ส่วนกลาง</span
                    >
                  </v-col>
                  <v-col cols="12" md="12" class="text-center">
                    <v-btn
                      color="info"
                      :href="'#/user/print_14_form/' + user.id_card"
                      target="_blank"
                      ><v-icon>mdi-printer</v-icon>
                      แบบฟอร์มเอกสารการแสดงความรู้ความสามารถ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card> -->

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-electron-framework</v-icon>
                    ปัจจุบันปฏิบัติการสอน
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnels.teaching_status"
                        >
                          <v-radio value="no_tech">
                            <template v-slot:label>
                              <div>
                                <strong
                                  class="warning--text"
                                  v-on:click="clear_tech()"
                                  >ไม่ได้ทำการสอน</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="teacher">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ทำการสอน</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="transference_personnels.subject_1"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 1:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="transference_personnels.subject_2"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 2:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="transference_personnels.subject_3"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 3:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="transference_personnels.subject_4"
                            :counter="100"
                            outlined
                            label="วิชาที่สอน ที่ 4:"
                            prepend-icon="mdi-notebook"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            v-model="
                              transference_personnels.perform_other_duties
                            "
                            outlined
                            label="หน้าที่อื่นที่ได้รับมอบหมาย :"
                            prepend-icon="mdi-account-network"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-office-building-marker</v-icon>
                    อยู่ระหว่างช่วยปฏิบัติราชการหรือไม่
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnels.service_status"
                        >
                          <v-radio value="not_service">
                            <template v-slot:label>
                              <div>
                                <strong
                                  class="primary--text"
                                  v-on:click="clear_gov_service()"
                                  >ไม่อยู่</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="gov_service">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text"
                                  >ช่วยปฏิบัติราชการ</strong
                                >
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card class="pa-2 ma-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                            :disabled="
                              transference_personnels.service_status ===
                                'not_service'
                            "
                            outlined
                            :items="colleges"
                            item-text="college_name"
                            item-value="college_ID"
                            v-model="transference_personnels.ser_college_code"
                            label="สถานศึกษา"
                            prepend-icon="mdi-office-building-marker"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :disabled="
                              transference_personnels.service_status ===
                                'not_service'
                            "
                            v-model="transference_personnels.ser_order"
                            :counter="100"
                            outlined
                            label="เริ่มปฏิบัติราชการ คำสั่งที่ :"
                            prepend-icon="mdi-office-building-marker"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">

                          <v-menu
                            v-model="menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                              :disabled="
                                  transference_personnels.service_status ===
                                    'not_service'
                                "
                                v-model="transference_personnels.ser_date"
                                label="เมื่อวันที่ :"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="transference_personnels.ser_date"
                              @input="menu3 = false"
                              locale="th"
                            ></v-date-picker>
                          </v-menu>

                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            :disabled="
                              transference_personnels.service_status ===
                                'not_service'
                            "
                            v-model="transference_personnels.ser_reason_help"
                            :counter="100"
                            outlined
                            label="เหตุผลที่ช่วยปฏิบัติราชการ :"
                            prepend-icon="mdi-office-building-marker"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate</v-icon>
                    อยู่ระหว่างทดลองปฏิบัติราชการหรือการเตรียมความพร้อมและพัฒนาอย่างเข้มหรือไม่
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnels.in_preparation"
                        >
                          <v-radio value="no_prepare">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="prepare">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate-outline</v-icon>
                    อยู่ระหว่างลาศึกษาต่อเต็มเวลาหรือไม่
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnels.on_study_leave"
                        >
                          <v-radio value="no_leave">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="leave">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-account-reactivate-outline</v-icon>
                    ไม่ติดเงื่อนไขการบรรจุและแต่งตั้งตามหลักเกณฑ์และวิธีการสอบแข่งขันหรือคัดเลือก
                    หรือเงื่อนไขการแต่งตั้ง
                    ให้มีหรือเลื่อนวิทยฐานะตามหลักเกณฑ์และวิธีการที่ ก.ค.ศ.
                    กำหนด หรือเงื่อนไขอื่นใดที่ ก.ค.ศ. หรือหน่วยงานอื่นกำหนด
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row>
                      <v-alert
                        class="mx-auto justify-center pa-2 ma-2"
                        border="bottom"
                        colored-border
                        type="warning"
                        elevation="2"
                      >
                        <v-radio-group
                          row
                          v-model="transference_personnels.not_conditions"
                        >
                          <v-radio value="not_con">
                            <template v-slot:label>
                              <div>
                                <strong class="primary--text">ไม่อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="con">
                            <template v-slot:label>
                              <div>
                                <strong class="warning--text">อยู่</strong>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </v-alert>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-card>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex col12 md6>
                            <v-text-field
                              :disabled="
                                transference_personnels.not_conditions ===
                                  'not_con'
                              "
                              v-model="
                                transference_personnels.not_conditionsOrder
                              "
                              :counter="100"
                              outlined
                              label="ตามคำสั่งที่ :"
                              prepend-icon="mdi-office-building-marker"
                            ></v-text-field>
                          </v-flex>

                          <v-flex col12 md6>
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="date"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  :disabled="
                                    transference_personnels.not_conditions ===
                                      'not_con'
                                  "
                                  v-model="
                                    transference_personnels.not_conditionsDate
                                  "
                                  label="ลงวันที่ :"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="
                                  transference_personnels.not_conditionsDate
                                "
                                scrollable
                                locale="th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.dialog.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-flag-letiant</v-icon>
                    ขอย้ายไปปฏิบัติหน้าที่ในตำแหน่งและหน่วยงานแห่งใหม่
                    เรียงลำดับดังนี้
                  </div>
                </v-card-title>
                <v-col cols="12" sm="12">
                  <v-card class="pa-2 ma-2">
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-card class="px-5 py-3" color="#FADBD8">
                          <v-btn
                            class="ma-2"
                            x-large
                            color="success"
                            dark
                            rounded
                            @click.stop="addtransference_locationdialogsubmit()"
                          >
                            <v-icon>mdi-selection-multiple-marker</v-icon>
                            <span> เลือกหน่วยงานแห่งใหม่</span>
                          </v-btn>
                          <v-data-table
                            color="success"
                            :loading="loading"
                            :headers="header_trans"
                            :items="transference_locations"
                          >
                            <template v-slot:item.action="{ item }">
                              <v-icon
                                color="red"
                                @click.stop="
                                  transference_locationDelete(item.id_tfl)
                                "
                              >
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <h3>
                          ขอย้ายสับเปลี่ยนกับ :
                          {{ personnel_temporarys.title_s }}
                          {{ personnel_temporarys.frist_name }}
                          {{ personnel_temporarys.last_name }}
                        </h3>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="transference_personnels.switch_position"
                          :counter="13"
                          outlined
                          label="บัตรประชาชน"
                          prepend-icon="mdi-account-details"
                          request
                          v-on:keyup.enter="OnEnterUpdate()"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <v-text-field
                          :value="personnel_temporarys.position_name"
                          :counter="100"
                          outlined
                          label="ตำแหน่ง :"
                          prepend-icon="mdi-account-star"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3">
                        <v-text-field
                          :value="personnel_temporarys.rang_name"
                          :counter="100"
                          outlined
                          label="วิทยฐานะ :"
                          prepend-icon="mdi-account-details"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :value="personnel_temporarys.id_position"
                          :counter="100"
                          outlined
                          label="เลขที่ตำแหน่ง :"
                          prepend-icon="mdi-account-details"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-text-field
                          :value="personnel_temporarys.college_name"
                          :counter="100"
                          outlined
                          label="สังกัด :"
                          prepend-icon="mdi-flag-letiant"
                          request
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <span
                          >*
                          กรณีย้ายสับเปลี่ยนให้กรอกหมายเลขบัตรประจำตัวประชาชนของผู้ที่ท่านประสงค์สับเปลี่ยน
                          และกดปุ่ม Enter เพื่อดำเนินการค้นหา
                          หากไม่ปรากฏแสดงว่าไม่มีบุคคลดังกล่าวในระบบ
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div
                    class="font-weight-light v-size--x-large"
                    color="pink darken-2"
                  >
                    <v-icon large left>mdi-account-details</v-icon>
                    เหตุผลการขอย้าย
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-col cols="12" sm="12">
                  <v-card class="pa-2">
                    <v-card-text>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            <v-icon></v-icon> ย้ายเพื่ออยู่ร่วมคู่สมรส
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="transference_personnels.reason_1"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-text-field
                          v-model="transference_personnels.reason_1_spouse"
                          :disabled="!transference_personnels.reason_1"
                          label="คู่สมรสชื่อ : "
                        ></v-text-field>
                        <v-text-field
                          v-model="transference_personnels.reason_1_occupation"
                          :disabled="!transference_personnels.reason_1"
                          label="คู่สมรสประกอบอาชีพ : "
                        ></v-text-field>
                        <v-text-field
                          v-model="transference_personnels.reason_1_location"
                          :disabled="!transference_personnels.reason_1"
                          label="สถานที่ประกอบอาชีพของคู่สมรส : "
                        ></v-text-field>
                        <v-autocomplete
                          v-model="transference_personnels.reason_1_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!transference_personnels.reason_1"
                          label="ภูมิลำเนาของคู่สมรส จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            ย้ายเพื่อดูแลบิดา มารดา :
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="transference_personnels.reason_2"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-autocomplete
                          :items="reason_2_fyears"
                          v-model="transference_personnels.reason_2_fyear"
                          :disabled="!transference_personnels.reason_2"
                          label="อายุของบิดาปี"
                        ></v-autocomplete>
                        <v-autocomplete
                          :items="reason_2_myears"
                          v-model="transference_personnels.reason_2_myear"
                          :disabled="!transference_personnels.reason_2"
                          label="อายุของมารดาปี"
                        ></v-autocomplete>
                        <v-autocomplete
                          v-model="transference_personnels.reason_2_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!transference_personnels.reason_2"
                          label="ภูมิลำเนาของบิดา มารดา จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            ย้ายกลับภูมิลำเนาของข้าพเจ้า จังหวัด :
                          </div>
                        </v-card-title>

                        <v-checkbox
                          v-model="transference_personnels.reason_3"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-autocomplete
                          v-model="transference_personnels.reason_3_domicile"
                          :items="provices_sh"
                          item-text="province_name"
                          item-value="province_ID"
                          :disabled="!transference_personnels.reason_3"
                          label="ภูมิลำเนาของข้าพเจ้า จังหวัด : "
                        ></v-autocomplete>
                      </v-row>
                      <v-row align="center">
                        <v-card-title>
                          <div class="font-weight-light v-size--x-large">
                            เหตุผลอื่น :
                          </div>
                        </v-card-title>
                        <v-checkbox
                          v-model="transference_personnels.reason_4"
                          value="1"
                          hide-details
                          class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        <v-textarea
                          v-model="transference_personnels.reason_4_detail"
                          :disabled="!transference_personnels.reason_4"
                          label="(ระบุ) : "
                        ></v-textarea>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-card>

              <v-card class="pa-2 ma-2">
                <v-card-title>
                  <div class="font-weight-light v-size--x-large">
                    <v-icon large left>mdi-details</v-icon>
                    เอกสารประกอบการพิจารณา
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="updatetransference_personnelsSubmit()"
                    class="ma-2"
                    fab
                    small
                    text
                    ><v-icon color="green">mdi-content-save</v-icon></v-btn
                  >
                </v-card-title>

                <v-row>
                  <v-col cols="12" sm="12">
                    <v-row class="pa-4 mt-0">
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_1"
                          label="สำเนา กพ.7/ก.ค.ศ.16 ที่เป็นปัจจุบัน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_2"
                          label="สำเนาทะเบียนบ้านของตน"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_3"
                          label="สำเนาทะเบียนบ้านของบิดา มารดา"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_4"
                          label="สำเนาทะเบียนบ้านของคู่สมรส"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_5"
                          label="สำเนาทะเบียนสมรส"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="transference_personnels.evidence_info_6"
                          label="สำเนาคำสั่งย้ายไปดำรงตำแหน่งใหม่ของคู่สมรส"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-form>
          <v-card
          color="#FFE0B2"
            class="pa-1 d-flex justify-center"
            v-if="
              get_gov_Age_year > 0 &&
                user.position_name === 'ครู' &&
                personnel_education_check.count_ed !== '0'
            "
          >
            <div>
              <v-row
                v-if="
                  transference_personnels.transfer_status === 'temporary' ||
                    transference_personnels.transfer_status === ''
                "
              >
                <div class="text-center">
                  <h3 class="red--text">
                    * เมื่อกดปุ่มบันทึกและยืนยันข้อมูลแล้ว
                    ท่านจะไม่สามารถแก้ไขข้อมูลใดๆ ได้
                    กรุณาตรวจสอบข้อมูลให้ถูกต้องการกดปุ่ม บันทึก
                  </h3>
                  <h2 class="success--text">
                    * เมื่อกดปุ่มบันทึกแล้วจะปรากฎปุ่มสำหรับพิมพ์แบบเสนอขอย้าย
                  </h2>
                  <v-btn
                    rounded
                    x-large
                    color="secondary"
                    target="_blank"
                    @click="previewReportShow()"
                    ><v-icon class="pr-2">mdi-eye</v-icon>
                    แบบฟอร์มตัวอย่างคำร้องขอย้าย</v-btn
                  >
                </div>
              </v-row>
              <v-row>
                <v-col align="center">
                  <v-btn
                    v-if="transference_personnels.transfer_status === 'save'"
                    rounded
                    x-large
                    color="info"
                    dark
                    class="ma-2"
                    :href="'#/user/print_info_sp/' + id_ref"
                    target="_blank"
                  >
                    <v-icon>mdi-printer</v-icon>
                    <span>พิมพ์แบบแสดงความประสงค์ขอย้าย กรณีพิเศษ</span>
                  </v-btn>

                

                  <v-btn
                    v-else-if="
                      transference_personnels.transfer_status === 'temporary'
                    "
                    x-large
                    color="success"
                    dark
                    rounded
                    class="ma-2"
                    @click.stop="confirmsSubmit()"
                  >
                    <v-icon>mdi-content-save</v-icon>
                    <span>บันทึกนำส่งเสนอพิจารณา</span>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </base-material-card>

        <v-dialog v-model="previewReport" width="80%">
          <v-card>
            <iframe
              style="width:100%; height: 600px;"
              :src="'#/user/print_info_previewSp/' + id_ref"
            ></iframe>
          </v-card>
        </v-dialog>

        <!--addtransference_locationdialog  -->
        <v-layout>
          <v-dialog
            v-model="addtransference_locationdialog"
            persistent
            max-width="80%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-account-multiple"
                title="เลือกวิทยาลัย"
                class="px-5 py-3"
              >
                รหัสรายการอ้างอิง {{ id_ref }}
              </base-material-card>
              <v-card-text>
                <v-form ref="addtransference_locationform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md2>
                        <v-select
                          v-model="addtransference_location.tlsequence_n"
                          :items="order_need"
                          outlined
                          label="ลำดับที่ :"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex md5>
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          outlined
                          label="สาขาวิชา :"
                          prepend-icon="mdi-account-details"
                          request
                          v-model="addtransference_location.tlid_branch"
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex md5>
                        <v-autocomplete
                          v-model="addtransference_location.tlcollege_code"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          outlined
                          label="สังกัด :"
                          prepend-icon="mdi-flag-letiant"
                          request
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <small>* จำเป็น</small>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  large
                  @click.stop="addtransference_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon> ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="success"
                  @click.stop="addtransference_locationSubmit()"
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;&nbsp;บันทึก
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model deleteuserdialog -->
        <v-layout>
          <v-dialog
            v-model="delettransference_locationdialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบรายการ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12>
                            ยืนยันการลบ
                            <span>
                              {{ edittransference_location.college_name }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="delettransference_locationdialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletetransference_locationSubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model confirm -->
        <v-layout>
          <v-dialog v-model="confirm_dialog" persistent max-width="40%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="primary"
                icon="mdi-content-save"
                title="ยืนยันรายการ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-card>
                  <v-card-text>
                    <v-form
                      ref="deletetransference_locationform"
                      lazy-validation
                    >
                      <v-container grid-list-md>
                        <v-alert outlined type="error" prominent border="left">
                          <h2>
                            เตือน
                          </h2>
                          <h3>
                            หากกดปุ่มบันทึกท่านไม่สามารถ แก้ไขข้อมูลใดๆ ได้แล้ว
                            กรุณาตรวจสอบให้ถูกต้องก่อนการบันทึก
                          </h3>
                        </v-alert>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large rounded @click.stop="confirm_dialog = false"
                  ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
                >
                <v-btn
                  large
                  color="primary"
                  @click.stop="updatetransference_personnelsSaveSubmit()"
                  dark
                  rounded
                >
                  <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันส่งคำร้อง
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>

        <!-- V-model Alert Work -->
        <v-layout>
          <v-dialog v-model="rest_Daydialog" persistent max-width="50%">
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-content-save"
                title="ปิดระบบ"
                class="px-5 py-3 "
              >
              </base-material-card>

              <v-card-text class="">
                <v-container grid-list-md>
                  <v-alert outlined type="error" prominent border="left">
                    <h3>
                      กรุณาเข้าทำระบบใหม่ในวันเวลาราชการ
                    </h3>
                  </v-alert>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn fab text large rounded @click.stop="closePage()"
                  ><v-icon dark>mdi-close</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-col>
    </v-row>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-container>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HrvecTransferencePersonnel",
  components: { FlipCountdown },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      isHidden: false,
      timestamp: "",

      colleges: {},
      personnel_search: {},
      show_personnel: [],
      branch_s: [],
      transference_locations: [],
      search: "",
      isHidden_service_gov: false,
      service_gov: false,
      addtransference_locationdialog: false,
      delettransference_locationdialog: false,
      rest_Daydialog: false,
      Search_Personneldialog: false,
      confirm_dialog: false,
      user: {},
      transference_personnel: [],
      transference_personnels: [],

      addtransference_personnel: {
        reason_1: false,
        reason_2: false,
        reason_3: false,
        reason_4: false,
        subject_1: "-",
        subject_2: "-",
        subject_3: "-",
        subject_4: "-",
        ser_college_code: "-",
        ser_order: "-",
        ser_reason_help: "-",
        teaching_status: "teacher",
        service_status: "not_service",
        in_preparation: "no_prepare",
        on_study_leave: "no_leave",
        not_conditions: "not_con",
        switch_position: "-",
        perform_other_duties: "-"
      },

      addtransference_location: {},
      edittransference_location: [],
      provinces: [],
      provices_sh: [],
      prefectures: [],

      personnel_educations: [],
      personnel_education_check: [],
      district: [],
      district_post_s: [],
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      order_need: [1, 2, 3],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "tlsequence_n" },
        { text: "รหัสอ้างอิง", align: "center", value: "tlid_ref" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "ยกเลิก", align: "center", value: "action" }
      ],
      education_s: [
        {
          title: "ต่ำกว่าปริญญาตรี",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        },
        {
          title: "ปริญญาตรี",
          faculty: "บธ.บ.",
          branch: "ระบบสารสนเทศทางคอมพิวเตอร์",
          color: "green"
        },
        {
          title: "ปริญญาโท",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        },
        {
          title: "ปริญญาเอก",
          faculty: "",
          branch: "",
          color: "deep-purple lighten-1"
        }
      ],

      isEditing: null,
      model: null,
      show1: false,
      pagination: {},
      personnel_marriages: [],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      personnel_temporarys: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      menu4: false,
      periods: [],
      period_enable: "1",
      updatetransference_personnels: {},
      periodTeachers: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      reason_2_fyears: [
        "เสียชีวิต",
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
        91,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
        100,
        101,
        102,
        103,
        104,
        105,
        106,
        107,
        108,
        109,
        110,
        111,
        112,
        113,
        114,
        115,
        116,
        117,
        118,
        119,
        120
      ],
      reason_2_myears: [
        "เสียชีวิต",
        30,
        31,
        32,
        33,
        34,
        35,
        36,
        37,
        38,
        39,
        40,
        41,
        42,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        50,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        59,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        67,
        68,
        69,
        70,
        71,
        72,
        73,
        74,
        75,
        76,
        77,
        78,
        79,
        80,
        81,
        82,
        83,
        84,
        85,
        86,
        87,
        88,
        89,
        90,
        91,
        92,
        93,
        94,
        95,
        96,
        97,
        98,
        99,
        100,
        101,
        102,
        103,
        104,
        105,
        106,
        107,
        108,
        109,
        110,
        111,
        112,
        113,
        114,
        115,
        116,
        117,
        118,
        119,
        120
      ],
      previewReport: false,


    };
  },

  async mounted() {
    await this.sweetAlertLoading();
    await this.checkDay();

    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    let result_branch;
    result_branch = await this.$http.post("branch.php", {
      ApiKey: this.ApiKey
    });
    this.branch_s = result_branch.data;

    let result_provice;
    result_provice = await this.$http.post("province_sh.php", {
      ApiKey: this.ApiKey
    });
    this.provices_sh = result_provice.data;

    let result;
    result = await this.$http.post("collegetype.php", {
      ApiKey: this.ApiKey
    });
    this.collegetypes = result.data;

    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;

    result = await this.$http.post("province.php", {
      ApiKey: this.ApiKey
    });
    this.provinces = result.data;
    result = await this.$http.post("region.php", {
      ApiKey: this.ApiKey
    });
    this.regions = result.data;

    let result_user;
    result_user = await this.$http.post("personnel_temporary.php", {
      ApiKey: this.ApiKey,
      id_card: userSession.id_card
    });
    this.user = result_user.data;

    await this.periodQueryAll();
    await this.transference_personnelAll();
    await this.transference_locationQueryAll();
    await this.personnel_educationsQueryAll();
    await this.personnel_educations_checkQueryAll();

    await this.timeCheck(); /* ย้ายครู */

    Swal.close();

    await this.educationCheck();
  },

  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          
        },
        onBeforeOpen: () => {
          Swal.showLoading();
          
        },
        onAfterClose: () => {
          Swal.hideLoading();
          
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async position_nameAssiss() {
      if (this.user.position_name === "ครูผู้ช่วย") {
        Swal.fire({
          icon: "error",
          title:
            " ท่านขาดคุณสมบัติในการย้าย              ท่านอยู่ในระหว่างการเตรียมความพร้อมและพัฒนาอย่างเข้ม              ของข้าราชการครูและบุคลากรทางการศึกษา แหน่งครูผู้ช่วย"
        });
        this.$router.push("/UserProfile");
      }
    },

    async type_personnelLocalDev() {
      if (
        this.user.type_personnel === "local_dev" &&
        this.get_gov_Age_year < 5
      ) {
        Swal.fire({
          icon: "error",
          title:
            "ท่านต้องมีเงือนไขในการย้ายสำหรับครูโครงการพัฒนาท้องถิ่นครบตามกำหนด"
        });
        this.$router.push("/UserProfile");
      }
    },

    async get_gov_Age_yearCheck() {
      if (this.get_gov_Age_year <= 1) {
        Swal.fire({
          icon: "error",
          title:
            " ตามนัยหนังสือสำนักงาน ก.ค.ศ. ที่ ศธ 0206.4/ว 18 ลงวันที่ 17 สิงหาคม 2556 คุณสมบัติของผู้ขอย้าย จะต้องดำรงตำแหน่งในหน่วยงานการศึกษาปัจจุบันไม่น้อยกว่า 24 เดือน"
        });
        this.$router.push("/UserProfile");
      }
    },

    async educationCheck() {
      if (this.personnel_education_check.count_ed === "0") {
        Swal.fire({
          icon: "warning",
          title: "ให้ดำเนินการบันทึกข้อมูลคุณวุฒิการศึกษาของท่านเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.$router.push("/personnel_education");
      }
    },

    async checkDay() {
      if (this.checkToday == "0" || this.checkToday == "6") {
        this.rest_Daydialog = true;
      }
    },
    async closePage() {
      this.$router.push("/UserProfile");
    },
    async previewReportShow() {
      this.previewReport = true;
    },
    async periodQueryAll() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: this.user_status_type
      });
      this.periods = result_period.data;
      if (
        this.periods.period_enable === "1" &&
        this.periods.period_type === "teacher"
      ) {
      } else {
        this.$router.push("/UserProfile");
      }
    },

    async timeCheck() {
      let dateNow = new Date();
      let dateStop = new Date(this.periods.period_stop + " 16:30:00");
      this.dateNows = dateNow.getTime();
      this.dateStops = dateStop.getTime();
      if (this.dateNows > this.dateStops) {
        await this.periodTeacherUpdate();
      } else {
      }
    },
    async periodTeacherUpdate() {
      this.periods.ApiKey = this.ApiKey;
      this.periods.period_enable = "0";
      await this.$http.post("period.update.php", this.periods);
    },

    async personnel_educationsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_educations = result.data;
    },

    async personnel_educations_checkQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education.check.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_education_check = result.data;
    },

    async OnEnter() {
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.addtransference_personnel.switch_position
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async OnEnterUpdate() {
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.transference_personnels.switch_position
        })
        .finally(() => (this.loading = false));
      this.personnel_temporarys = result.data;
    },
    async transference_personnelAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          id_ref: this.id_ref
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async transference_locationQueryAll() {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_ref: this.id_ref
      });
      this.transference_locations = result.data;
    },

    //First >> Insert transference Location
    async addtransference_locationdialogsubmit() {
      this.addtransference_location = {};
      this.addtransference_locationdialog = true;
    },

    async Search_Personnel() {
      this.personnel_select = {};
      this.Search_Personneldialog = true;
    },

    async addtransference_locationSubmit() {
      await this.timeCheck();
      await this.periodQueryAll();
      if (this.$refs.addtransference_locationform.validate()) {
        this.addtransference_location.ApiKey = this.ApiKey;
        this.addtransference_location.tlid_ref = this.id_ref;
        this.addtransference_location.tlid_card = this.user.id_card;
        let result = await this.$http.post(
          "transference_location.insert.php",
          this.addtransference_location
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title:
              "บันทึกข้อมูลผิดพลาด รายการลำดับ หรือ ชื่อหน่วยงานแหน่งใหม่ซ้ำ",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.addtransference_locationdialog = false;
      }
    },

    //Confirm data

    async confirmsSubmit() {
      this.confirm_dialog = true;
    },

    //Delete transference Location
    async transference_locationDelete(id_tfl) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
      this.edittransference_location = result.data;
      this.delettransference_locationdialog = true;
    },
    async deletetransference_locationSubmit() {
      if (this.$refs.deletetransference_locationform.validate()) {
        this.edittransference_location.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "transference_location.delete.php",
          this.edittransference_location
        );
        if (result.data.status == true) {
          this.transference_location = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการลบข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_locationQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการลบผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.delettransference_locationdialog = false;
      }
    },

    // Add transference_personnel
    async addtransference_personnelSubmit() {
      await this.sweetAlertLoading();
      await this.timeCheck();
      await this.periodQueryAll();

      if (this.$refs.addtransference_personnelform.validate()) {
        this.addtransference_personnel.ApiKey = this.ApiKey;
        this.addtransference_personnel.id_ref = this.id_ref;
        this.addtransference_personnel.time_s = this.periods.period_times;
        this.addtransference_personnel.year_s =
          this.periods.period_year;
        this.addtransference_personnel.id_card = this.user.id_card;
        this.addtransference_personnel.date_time = this.date_today;
        this.addtransference_personnel.age_time = this.get_gov_Age;
        this.addtransference_personnel.age_app_time = this.get_Appoint_Age;
        this.addtransference_personnel.type_move ='sp';
        this.addtransference_personnel.transfer_status = "temporary";
        this.addtransference_personnel.ser_time_year = this.get_gov_Age_year_service;
        this.addtransference_personnel.ser_time_month = this.get_gov_Age_month_service;
        this.addtransference_personnel.college_code_now = this.user.college_code;

        let result = await this.$http.post(
          "transference_personnel.insert.php",
          this.addtransference_personnel
        );

        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        /* this.loadOnce() */
        this.addtransference_locationdialog = false;
        this.confirm_dialog = false;
        Swal.close();
      }
    },

    async updatetransference_personnelsSubmit() {
      await this.sweetAlertLoading();
      await this.timeCheck();
      await this.periodQueryAll();
      if (this.$refs.updatetransference_personnelsform.validate()) {
        this.updatetransference_personnels.ApiKey = this.ApiKey;
        this.updatetransference_personnels.id_ref = this.id_ref;
        this.updatetransference_personnels.id_tfp = this.transference_personnels.id_tfp;

        this.updatetransference_personnels.age_time = this.get_gov_Age;
        this.updatetransference_personnels.age_app_time = this.get_Appoint_Age;

        this.updatetransference_personnels.subject_1 = this.transference_personnels.subject_1;
        this.updatetransference_personnels.subject_2 = this.transference_personnels.subject_2;
        this.updatetransference_personnels.subject_3 = this.transference_personnels.subject_3;
        this.updatetransference_personnels.subject_4 = this.transference_personnels.subject_4;
        this.updatetransference_personnels.perform_other_duties = this.transference_personnels.perform_other_duties;
        this.updatetransference_personnels.service_status = this.transference_personnels.service_status;
        this.updatetransference_personnels.ser_college_code = this.transference_personnels.ser_college_code;
        this.updatetransference_personnels.ser_order = this.transference_personnels.ser_order;
        this.updatetransference_personnels.ser_date = this.transference_personnels.ser_date;
        this.updatetransference_personnels.ser_time_year = this.transference_personnels.ser_time_year;
        this.updatetransference_personnels.ser_time_month = this.transference_personnels.ser_time_month;
        this.updatetransference_personnels.ser_reason_help = this.transference_personnels.ser_reason_help;
        this.updatetransference_personnels.in_preparation = this.transference_personnels.in_preparation;
        this.updatetransference_personnels.on_study_leave = this.transference_personnels.on_study_leave;
        /*  */
        this.updatetransference_personnels.not_conditions = this.transference_personnels.not_conditions;
        this.updatetransference_personnels.not_conditionsOrder = this.transference_personnels.not_conditionsOrder;
        this.updatetransference_personnels.not_conditionsDate = this.transference_personnels.not_conditionsDate;
        /*  */
        this.updatetransference_personnels.switch_position = this.transference_personnels.switch_position;
        this.updatetransference_personnels.reason_1 = this.transference_personnels.reason_1;
        this.updatetransference_personnels.reason_1_spouse = this.transference_personnels.reason_1_spouse;
        this.updatetransference_personnels.reason_1_occupation = this.transference_personnels.reason_1_occupation;
        this.updatetransference_personnels.reason_1_location = this.transference_personnels.reason_1_location;
        this.updatetransference_personnels.reason_1_domicile = this.transference_personnels.reason_1_domicile;
        this.updatetransference_personnels.reason_2 = this.transference_personnels.reason_2;
        this.updatetransference_personnels.reason_2_fyear = this.transference_personnels.reason_2_fyear;
        this.updatetransference_personnels.reason_2_myear = this.transference_personnels.reason_2_myear;
        this.updatetransference_personnels.reason_2_domicile = this.transference_personnels.reason_2_domicile;
        this.updatetransference_personnels.reason_3 = this.transference_personnels.reason_3;
        this.updatetransference_personnels.reason_3_domicile = this.transference_personnels.reason_3_domicile;
        this.updatetransference_personnels.reason_4 = this.transference_personnels.reason_4;
        this.updatetransference_personnels.reason_4_detail = this.transference_personnels.reason_4_detail;
        this.updatetransference_personnels.evidence_info_1 = this.transference_personnels.evidence_info_1;
        this.updatetransference_personnels.evidence_info_2 = this.transference_personnels.evidence_info_2;
        this.updatetransference_personnels.evidence_info_3 = this.transference_personnels.evidence_info_3;
        this.updatetransference_personnels.evidence_info_4 = this.transference_personnels.evidence_info_4;
        this.updatetransference_personnels.evidence_info_5 = this.transference_personnels.evidence_info_5;
        this.updatetransference_personnels.evidence_info_6 = this.transference_personnels.evidence_info_6;
        this.updatetransference_personnels.date_time = this.transference_personnels.date_time;

        let result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatetransference_personnels
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.transference_personnelAll();
        /* this.loadOnce(); */
        Swal.close();
      }
    },

    async updatetransference_personnelsSaveSubmit() {
      await this.timeCheck();
      await this.periodQueryAll();
      if (this.$refs.updatetransference_personnelsform.validate()) {
        this.updatetransference_personnels.ApiKey = this.ApiKey;
        this.updatetransference_personnels.id_ref = this.id_ref;
        this.updatetransference_personnels.id_tfp = this.transference_personnels.id_tfp;
        this.updatetransference_personnels.subject_1 = this.transference_personnels.subject_1;
        this.updatetransference_personnels.subject_2 = this.transference_personnels.subject_2;
        this.updatetransference_personnels.subject_3 = this.transference_personnels.subject_3;
        this.updatetransference_personnels.subject_4 = this.transference_personnels.subject_4;
        this.updatetransference_personnels.perform_other_duties = this.transference_personnels.perform_other_duties;
        this.updatetransference_personnels.service_status = this.transference_personnels.service_status;
        this.updatetransference_personnels.ser_college_code = this.transference_personnels.ser_college_code;
        this.updatetransference_personnels.ser_order = this.transference_personnels.ser_order;
        this.updatetransference_personnels.ser_date = this.transference_personnels.ser_date;
        this.updatetransference_personnels.ser_time_year = this.transference_personnels.ser_time_year;
        this.updatetransference_personnels.ser_time_month = this.transference_personnels.ser_time_month;
        this.updatetransference_personnels.ser_reason_help = this.transference_personnels.ser_reason_help;
        this.updatetransference_personnels.in_preparation = this.transference_personnels.in_preparation;
        this.updatetransference_personnels.on_study_leave = this.transference_personnels.on_study_leave;
        this.updatetransference_personnels.not_conditions = this.transference_personnels.not_conditions;
        this.updatetransference_personnels.not_conditionsOrder = this.transference_personnels.not_conditionsOrder;
        this.updatetransference_personnels.not_conditionsDate = this.transference_personnels.not_conditionsDate;

        this.updatetransference_personnels.switch_position = this.transference_personnels.switch_position;
        this.updatetransference_personnels.reason_1 = this.transference_personnels.reason_1;
        this.updatetransference_personnels.reason_1_spouse = this.transference_personnels.reason_1_spouse;
        this.updatetransference_personnels.reason_1_occupation = this.transference_personnels.reason_1_occupation;
        this.updatetransference_personnels.reason_1_location = this.transference_personnels.reason_1_location;
        this.updatetransference_personnels.reason_1_domicile = this.transference_personnels.reason_1_domicile;
        this.updatetransference_personnels.reason_2 = this.transference_personnels.reason_2;
        this.updatetransference_personnels.reason_2_fyear = this.transference_personnels.reason_2_fyear;
        this.updatetransference_personnels.reason_2_myear = this.transference_personnels.reason_2_myear;
        this.updatetransference_personnels.reason_2_domicile = this.transference_personnels.reason_2_domicile;
        this.updatetransference_personnels.reason_3 = this.transference_personnels.reason_3;
        this.updatetransference_personnels.reason_3_domicile = this.transference_personnels.reason_3_domicile;
        this.updatetransference_personnels.reason_4 = this.transference_personnels.reason_4;
        this.updatetransference_personnels.reason_4_detail = this.transference_personnels.reason_4_detail;
        this.updatetransference_personnels.evidence_info_1 = this.transference_personnels.evidence_info_1;
        this.updatetransference_personnels.evidence_info_2 = this.transference_personnels.evidence_info_2;
        this.updatetransference_personnels.evidence_info_3 = this.transference_personnels.evidence_info_3;
        this.updatetransference_personnels.evidence_info_4 = this.transference_personnels.evidence_info_4;
        this.updatetransference_personnels.evidence_info_5 = this.transference_personnels.evidence_info_5;
        this.updatetransference_personnels.evidence_info_6 = this.transference_personnels.evidence_info_6;
        this.updatetransference_personnels.transfer_status = "save";
        this.updatetransference_personnels.status_confirm = "save";
        this.updatetransference_personnels.date_time = this.transference_personnels.date_time;

        if (this.checkEventAss == 0) {
          Swal.fire({
            icon: "warning",
            title: "ยังไปได้เลือกปลายทาง",
            showConfirmButton: false,
            timer: 1500
          });
          this.addtransference_locationdialog = false;
          this.confirm_dialog = false;
        } else {
          let result = await this.$http.post(
            "transference_personnel.update.php",
            this.updatetransference_personnels
          );
          
          let result_up = await this.$http.post(
            "transference_location.update.php",
            this.updatetransference_personnels
          );

          if (result.data.status == true && result_up.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.transference_personnelAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          this.addtransference_locationdialog = false;
          this.confirm_dialog = false;
        }
      }
    },
    async clear_tech() {
      //this.$v.$reset();
      this.addtransference_personnel.subject_1 = "-";
      this.addtransference_personnel.subject_2 = "-";
      this.addtransference_personnel.subject_3 = "-";
      this.addtransference_personnel.subject_4 = "-";
    },
    async clear_gov_service() {
      //this.$v.$reset();
      this.addtransference_personnel.ser_college_code = "-";
      this.addtransference_personnel.ser_order = "-";
      this.addtransference_personnel.ser_date = "-";
      this.addtransference_personnel.ser_time_year = "-";
      this.addtransference_personnel.ser_time_month = "-";
      this.addtransference_personnel.ser_reason_help = "-";
      this.transference_personnels.ser_college_code = "";
      this.transference_personnels.ser_order = "";
      this.transference_personnels.ser_date = "";
      this.transference_personnels.ser_time_year = "";
      this.transference_personnels.ser_time_month = "";
      this.transference_personnels.ser_reason_help = "";
    },
    loadOnce() {
      location.reload();
    }
  },
  computed: {
    id_ref() {
      let id_ref_cr;
      id_ref_cr =
      "SP" +
        this.periods.period_times +
        this.periods.period_year +
        this.user.id_card;
      return id_ref_cr;
    },

    checkEventAss() {
      let data = this.transference_locations;
      let sum = 0;
      data.forEach(value => {
        sum = sum + parseInt(value[3]);
      });

      return sum;
    },
    user_status_type() {
      let user_status = this.user.user_status;
      let result;
      if (user_status == "teacher") {
        result = "teacher";
      } else if (user_status == "director") {
        result = "manage";
      } else if (user_status == "se_director") {
        result = "manage";
      }
      return result;
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    month_now() {
      const d = new Date();
      let month = d.getMonth() + 1;
      return month;
    },
    year_s() {
      const d = new Date();
      let year = d.getFullYear() + 543;
      return year;
    },

    marital_status() {
      let marital_status = this.user.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      }
      return marital_result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },

    dmy_app_cal() {
      let month = this.user.appoin_month;
      let day = this.user.appoin_day;
      let year = this.user.appoin_year - 543;
      let result = month + "/" + day + "/" + year;
      return result;
    },

    date_appoin_ch() {
      let monthNames = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ];
      let day = this.user.appoin_day;
      let month = monthNames[parseInt(this.user.appoin_month)];
      let year = this.user.appoin_year;
      let years = parseInt(year);
      let today = day + " " + month + " " + years;
      return today;
    },

    cal_age_gov() {
      const today = new Date();
      const birthDate = new Date(this.dmy_app_cal);
      const yearsDifference = today.getFullYear() - birthDate.getFullYear();
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        return yearsDifference - 1;
      }
      return yearsDifference;
    },
    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    date_month_cal() {
      let time_s = this.periods.period_times;
      if (time_s == 1) {
        let dd = "1";
        let mm = "4";
        let yyyy = parseInt(this.periods.period_year) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      } else if (time_s == 2) {
        let dd = "1";
        let mm = "10";
        let yyyy = parseInt(this.periods.period_year) - 543;
        let date = yyyy + "-" + mm + "-" + dd;
        return date;
      }
    },

    get_Appoint_Age() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    get_gov_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(
        parseInt(this.user.appoin_year) -
          543 +
          "-" +
          this.user.appoin_month +
          "-" +
          this.user.appoin_day
      );
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    },

    get_gov_Age_year_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_personnel.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },

    get_gov_Age_month_service() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.addtransference_personnel.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = months;
      return age;
    },

    get_gov_Age_year() {
      let today = new Date(this.periods.period_cal_end);
      let DOB = new Date(this.user.date_app_now);

      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years;
      return age;
    },
    checkToday() {
      const date = new Date();
      let day = date.getDay();
      return day;
    }
  },

  watch: {
    async date_transference(newVal, oldVal) {
      await this.transference_personnelAll();
    }
  }
};
</script>
